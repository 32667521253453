<template>
  <div
    v-if="selectedUnits.length > 1"
    class="UnitSelection">
    <div class="ContentWrap">
      <div class="LeftSide">
        <span class="TopText">{{ text.showForText }}</span>
        <span class="UnitTitle">{{ text.unitText }}</span>
      </div>

      <Button
        :text="mixWB('CHANGE')"
        :fullWidth="false"
        @button-click="onSelectUnitClick" />
    </div>

    <Dialog
      :isShowing="showUnitSelectionModal"
      :useComponent="UnitSelectionModal"
      @close="showUnitSelectionModal = false"
      @see-all-click="onSeeAllUnitsClick"
      @unit-click="onUnitClick" />
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import UnitSelectionModal from '@/components/ScreeningItems/UnitSelectionModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitSelection',
  data() {
    return {
      UnitSelectionModal,
      showUnitSelectionModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'currentScreeningSelectedFilterUnitID',
    ]),
    currentScreeningSelectedFilterUnit() {
      if (!this.currentScreeningSelectedFilterUnitID) {
        return false
      }
      return this.selectedUnits.find(
        (x) => x.id === this.currentScreeningSelectedFilterUnitID,
      )
    },
    currentScreeningSelectedFilterUnitTitle() {
      if (!this.currentScreeningSelectedFilterUnit) {
        return ''
      }

      return this.currentScreeningSelectedFilterUnit.getFullTitle()
    },
    text() {
      const data = {
        showForText: this.mixWB('SHOW_FOR'),
        unitText: this.mixWB('ALL_UNITS'),
      }

      if (!this.currentScreeningSelectedFilterUnit) {
        return data
      }

      data.showForText = this.mixWB('ONLY_SHOW_FOR')
      data.unitText = this.currentScreeningSelectedFilterUnitTitle

      return data
    },
  },
  methods: {
    onSelectUnitClick() {
      this.showUnitSelectionModal = true
    },
    onSeeAllUnitsClick() {
      this.showUnitSelectionModal = false
      this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', null)
    },
    onUnitClick(unitID) {
      this.showUnitSelectionModal = false
      this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', unitID)
    },
  },
  components: {
    Button,
    Dialog,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitSelection
    padding-top 15px
    padding-bottom 15px
    border-bottom 1px solid $color_grey_lighter
    .ContentWrap
      display flex
      align-items center
      justify-content space-between
      .TopText
        font-size 0.75rem
        color $color_grey
      .UnitTitle
        text-transform uppercase
        font-weight bold
        color $color_grey
      >>> .ButtonWrap
        margin 0
</style>
