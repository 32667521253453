<template>
  <div class="HUBAccountItem">
    <div class="LeftSide">
      <div class="ImageWrap" />
      <div class="InfoWrap">
        <span class="Title">{{ hubAccount.name }}</span>
        <div class="OptionsWrap">
          <Dropdown
            name="role-select"
            :options="roleOptions"
            :value="hubAccountItem.role"
            @on-update="onRoleUpdate" />
        </div>
      </div>
    </div>
    <div class="RightSide">
      <div
        class="RemoveIconWrap"
        @click="onRemoveClick">
        <BinIcon />
      </div>
    </div>
  </div>
</template>

<script>
import BinIcon from '@/assets/svg/with-padding/bins.svg?inline'
import Dropdown from '@/components/FormElements/Dropdown.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'HUBAccountItem',
  props: {
    hubAccountItem: {
      type: Object,
      required: true,
    },
    projectOwnerHUBAccountID: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['hubAccountsAsObject']),
    hubAccount() {
      return this.hubAccountsAsObject[this.hubAccountItem.hubAccountID]
    },
    roleOptions() {
      const availableRoles = [
        {
          value: '',
          name: this.mixWB('W: Kan se'),
        },
        {
          value: 'project-owner',
          name: this.mixWB('W: Projektejer'),
        },
      ]

      return availableRoles
    },
  },
  methods: {
    onRoleUpdate({ value }) {
      this.$emit('role-update', {
        role: value,
        hubAccountID: this.hubAccount.id,
      })
    },
    onRemoveClick() {
      this.$emit('remove', this.hubAccountItem.hubAccountID)
    },
  },
  components: {
    BinIcon,
    Dropdown,
  },
}
</script>

<style lang="stylus" scoped>
.HUBAccountItem
  display flex
  align-items center
  justify-content space-between
  padding 10px 0px 15px
  border-bottom 1px solid $grey_4
  .LeftSide
    display flex
    align-items center
    gap 10px
    .ImageWrap
      circle(60px)
      background-color $grey_2
      display none
    .InfoWrap
      display flex
      flex-direction column
      .Title
        font-weight bold
      .OptionsWrap
        display flex
        align-items center
        gap 10px
        margin-top 5px
        >>> .Dropdown
          min-width 100px
          margin-bottom 0
          select
            font-size 0.875rem
            padding-right 30px
  .RightSide
    .RemoveIconWrap
      box(40px)
      padding 8px
      cursor pointer
      margin-top 2.5px
</style>
