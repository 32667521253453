<template>
  <div class="HUBAccountsSelectModal">
    <span class="Title">{{ mixWB('W: HUB kunder') }}</span>

    <!-- Search for customer -->
    <div class="SectionWrap">
      <SearchSelect
        :data="''"
        :itemList="mutatedHubAccounts"
        @update="onHUBAccountSelect" />
    </div>

    <!-- Selected customers -->
    <div class="SectionWrap">
      <SectionHeadline
        :text="mixWB('W: Har adgang')"
        noBottomSpacing />

      <HUBAccountItem
        v-for="item in hubAccountItems"
        :key="item.hubAccountID"
        :hubAccountItem="item"
        :projectOwnerHUBAccountID="projectOwnerHUBAccountID"
        @role-update="onItemRoleUpdate"
        @remove="onRemoveItemClick" />

      <span
        class="EmptyText"
        v-if="!hubAccountItems.length">
        {{ mixWB('W: Ingen valgte') }}
      </span>
    </div>

    <div class="SectionWrap ButtonsWrap">
      <Button
        :text="mixWB('SAVE')"
        :isLoading="isSaving"
        :fullWidth="false"
        @button-click="onSaveClick" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import SearchSelect from '@/components/FormElements/SearchSelect.vue'
import HUBAccountItem from '@/components/Modals/HUBAccountsSelectModal/HUBAccountItem.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'HUBAccountsSelectModal',
  data() {
    return {
      hubAccountItems: [],
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'hubAccounts', 'currentScreeningData']),
    mutatedHubAccounts() {
      return this.hubAccounts
        .filter(
          (x) => !this.hubAccountItems.find((y) => y.hubAccountID === x.id),
        )
        .map((x) => ({ id: x.id, translation: x.name }))
    },
    projectOwnerHUBAccountID() {
      return (
        this.hubAccountItems.find((x) => x.role === 'project-owner')
          ?.hubAccountID ?? ''
      )
    },
  },
  methods: {
    setDataOnLoad() {
      this.currentScreeningData.hubAccountItems.forEach((hubAccountItem) => {
        this.hubAccountItems.push(
          this.getHUBAccountDataTemplate(hubAccountItem),
        )
      })
    },
    getHUBAccountDataTemplate({ hubAccountID, role }) {
      return {
        hubAccountID: hubAccountID ?? '',
        role: role ?? '',
      }
    },
    onHUBAccountSelect({ value }) {
      this.hubAccountItems.push(
        this.getHUBAccountDataTemplate({ hubAccountID: value }),
      )
    },
    onItemRoleUpdate({ role, hubAccountID }) {
      // Check to reset current project owner when new is selected
      if (role === 'project-owner') {
        this.hubAccountItems.forEach((item) => {
          if (item.role === 'project-owner') {
            item.role = ''
          }
        })
      }

      const item = this.hubAccountItems.find(
        (x) => x.hubAccountID === hubAccountID,
      )
      item.role = role
    },
    onRemoveItemClick(hubAccountID) {
      const hubIndex = this.hubAccountItems.findIndex(
        (x) => x.hubAccountID === hubAccountID,
      )

      if (hubIndex > -1) {
        this.hubAccountItems.splice(hubIndex, 1)
      }
    },
    onSaveClick() {
      this.isSaving = true

      // Set selected HUB account IDs
      const hubAccountIDs = this.hubAccountItems.map((x) => x.hubAccountID)

      // Save
      this.$store.dispatch('updateScreeningHUBAccounts', {
        hubAccountIDs,
        hubAccountItems: this.hubAccountItems,
      })

      setTimeout(() => {
        this.$emit('close-dialog')
      }, 250)
    },
  },
  components: {
    SectionHeadline,
    SearchSelect,
    HUBAccountItem,
    Button,
  },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
.HUBAccountsSelectModal
  display block
  >>> .InfoText
    margin-bottom 30px

.Title
  modalTitle()

.SectionWrap
  margin-bottom 20px
  &:last-child
    margin-bottom 0
  &.ButtonsWrap
    padding-top 20px
    display flex
    justify-content flex-end
    >>> .ButtonWrap
      margin-bottom 0
      .Button
        min-width 100px

.EmptyText
  padding-top 5px
  font-style italic
  color $grey_7
</style>
