<template>
  <div class="ScreeningMenu">
    <DotMenuItem
      :text="mixWB('PROJECT_DETAILS')"
      itemID="project-details"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('BUILDINGS')"
      itemID="buildings"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('BUILDER')"
      itemID="builder"
      @dot-item-click="onDotItemClick" />
    <DotSplitLine />
    <DotMenuItem
      :text="mixWB('GO_TO_OVERVIEW')"
      itemID="show-result"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('VIEW_IN_MILVA_ONLINE')"
      itemID="show-in-milva-online"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      v-if="currentUser.isDeveloper"
      :text="mixWB('SHOW_IN_BACKEND')"
      itemID="show-in-backend"
      @dot-item-click="onDotItemClick" />
    <DotSplitLine />
    <DotMenuItem
      :text="mixWB('COMMENTS_2')"
      itemID="project-comments"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'
import { mapGetters } from 'vuex'
import Screening from '@/globals/javascript/models/Screening'
import DotSplitLine from '@/components/DotMenu/DotSplitLine.vue'

export default {
  name: 'ScreeningMenu',
  props: {
    isScreeningComplete: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentAccount',
      'currentScreeningData',
    ]),
  },
  methods: {
    onDotItemClick(itemID) {
      if (itemID === 'project-details') {
        this.$router.push({ name: 'ScreeningsUpdateProjectDetails', params: { screeningID: this.$route.params.screeningID } })
      }
      if (itemID === 'buildings') {
        this.$router.push({ name: 'ScreeningsUpdateBuildings', params: { screeningID: this.$route.params.screeningID } })
      }
      if (itemID === 'builder') {
        this.$router.push({ name: 'ScreeningsUpdateBuilder', params: { screeningID: this.$route.params.screeningID } })
      }
      if (itemID === 'show-in-milva-online') {
        window.open(this.currentScreeningData.getMilvaOnlineLink(), '_blank')
      }
      if (itemID === 'show-result') {
        this.$router.push({ name: 'ResultOverview', params: { screeningID: this.$route.params.screeningID } })
      }
      if (itemID === 'show-in-backend') {
        Screening.showInBackend(this.$route.params.screeningID)
      }
      if (itemID === 'project-comments') {
        this.$emit('pass-to-parent', itemID)
      }

      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
    DotSplitLine,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningMenu
    display block
</style>
