<template>
  <div class="ScreeningHeader">
    <span class="Address">{{ currentScreeningData.getAddressLine() }}</span>

    <div class="ChipList">
      <Chip
        v-if="!currentScreeningData.isStarted"
        :text="mixWB('STATUS_NOT_STARTED')"
        size="small" />
      <Chip
        v-if="caseNumber"
        :text="caseNumber"
        size="small" />
      <Chip
        v-if="personalSamplingID"
        :text="personalSamplingID"
        size="small" />
      <Chip
        v-if="currentScreeningData.enterpriseID"
        :text="mixWB('ENTERPRISE_PROJECT')"
        size="small" />
      <Chip
        v-if="projectTypeText"
        :text="projectTypeText"
        size="small" />
      <Chip
        v-if="currentScreeningData.isScreening"
        :text="mixWB('SCREENING')"
        size="small" />
      <Chip
        v-if="noOfUnitsText"
        :text="noOfUnitsText"
        size="small" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Chip from '@/components/Chips/Chip.vue'

export default {
  name: 'ScreeningHeader',
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'caseNumber',
      'personalSamplingID',
      'noOfSelectedUnits',
    ]),
    projectType() {
      if (this.currentScreeningData) {
        return this.currentScreeningData.projectType
      }
      return null
    },
    noOfUnitsText() {
      if (this.noOfSelectedUnits) {
        return `${
          this.noOfSelectedUnits === 1 ? this.mixWB('1_UNIT') : this.mixWB('X_UNITS', [this.noOfSelectedUnits])
        }`
      }
      return false
    },
    projectTypeText() {
      if (this.projectType === 'renovation') {
        return this.mixWB('RENOVATION')
      }
      if (this.projectType === 'total-demolition') {
        return this.mixWB('TOTAL_DEMOLITION')
      }
      return false
    },
  },
  components: {
    Chip,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningHeader
    position relative
    padding-bottom 5px
    border-bottom 1px solid $color_grey_lighter
    .Address
      display block
      color $color_grey_dark
      text-transform uppercase
      font-weight bold
      font-size 1rem
      margin-bottom 5px
    span
      font-size 0.875rem
      color $color_grey

  .ChipList
    display flex
    flex-wrap wrap
    margin 0 -5px
    > div
      margin 0 2.5px 5px
</style>
