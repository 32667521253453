<template>
  <router-link
    :class="{ 'IsApproved': item.status.isApproved }"
    class="CategoryItem"
    :to="{
      name: 'ScreeningsUpdateCategory',
      params: { screeningID: currentScreening.id, categoryID: item.id }
    }">
    <span class="ItemTitle">{{ mixWB(item.translation) }}</span>
    <div class="ItemIcon">
      <CategoryIcon :categoryID="item.id" />
      <div class="CountersWrap">
        <span
          class="Counter SampleCount"
          v-if="item.sampleCount">{{ item.sampleCount }}</span>
        <span
          class="Counter AssessmentCount"
          v-if="item.assessmentCount">{{ item.assessmentCount }}</span>
      </div>
    </div>
    <span class="ItemStatus">{{ item.status.text }}</span>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoryIcon from '@/components/Icons/CategoryIcon.vue'

export default {
  name: 'CategoryItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentScreening',
    ]),
  },
  components: {
    CategoryIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryItem
    display flex
    flex-direction column
    align-items center
    width calc(20% - 10px)
    margin 0 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    +below($desktop)
      width calc(25% - 10px)
    +below($tablet)
      width calc(33.33% - 10px)
    &.IsApproved
      display flex
      background-color lighten($color_primary, 75%)
      border-color lighten($color_primary, 50%)
      .ItemIcon
        background-color #fff
      .ItemStatus
        background-color lighten($color_primary, 0%)
        color #fff

  .ItemTitle
    display flex
    align-items center
    justify-content center
    width 100%
    padding 5px
    font-size 0.875rem
    text-align center
    text-transform uppercase
    min-height 36px
    line-height 1

  .ItemIcon
    position relative
    display flex
    align-items center
    circle(50px)
    padding 10px
    border 1px solid $color_primary
    margin-bottom 10px
    svg
      box(100%)
      fill $color_grey

  .ItemStatus
    display flex
    align-items center
    justify-content center
    flex-grow 2
    width 100%
    padding 2px 5px 3px
    background-color $color_grey_lighter
    font-size 0.75rem
    text-transform uppercase

  .CountersWrap
    .Counter
      position absolute
      display flex
      align-items center
      justify-content center
      circle(12px)
      color #fff
      font-size 0.665rem
      &.SampleCount
        background-color $color_samples
      &.AssessmentCount
        background-color $color_assessments
      &:last-child
        top 12px
        right -6px
      &:first-child
        top 0px
        right 2px

</style>
