<template>
  <div class="ScreeningsUpdateFirstSteps">
    <div class="List">
      <router-link
        v-if="!projectType"
        class="FirstStepLink"
        :to="{
          name: 'ScreeningsUpdateProjectDetails',
          params: { screeningID: this.$route.params.screeningID }
        }">
        <ProjectOptionListItem :item="projectDetailsItem" />
      </router-link>
      <router-link
        v-if="!noOfSelectedUnits"
        class="FirstStepLink"
        :to="{
          name: 'ScreeningsUpdateBuildings',
          params: { screeningID: this.$route.params.screeningID }
        }">
        <ProjectOptionListItem :item="unitsItem" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ProjectOptionListItem from '@/components/UpdateFlow/ProjectOptionListItem.vue'
import { mapGetters } from 'vuex'

import InfoIcon from '@/assets/svg/with-padding/information.svg?inline'
import UnitsIcon from '@/assets/svg/with-padding/buildings.svg?inline'

export default {
  name: 'ScreeningsUpdateFirstSteps',
  data() {
    return {
      InfoIcon,
      UnitsIcon,
    }
  },
  computed: {
    ...mapGetters([
      'projectType',
      'noOfSelectedUnits',
    ]),
    projectDetailsItem() {
      return {
        id: 'details',
        icon: this.InfoIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('ADD_PROJECT_DETAILS'),
        subText: this.mixWB('REQUIRED'),
      }
    },
    unitsItem() {
      return {
        id: 'units',
        icon: this.UnitsIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('ADD_UNITS'),
        subText: this.mixWB('REQUIRED'),
      }
    },
  },
  components: {
    ProjectOptionListItem,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateFirstSteps
    display block
</style>
