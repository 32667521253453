<template>
  <div class="ScreeningsUpdateFlow">

    <!-- Rest of the steps -->
    <template>
      <!-- Unit selection -->
      <UnitSelection />

      <!-- Next step button -->
      <NextStepLine />

      <!-- Screening areas -->
      <div
        class="AreaList"
        v-for="area in areasAsArray"
        :key="area.id">
        <span class="SectionTitle">{{ mixWB(area.translation) }}</span>
        <div class="CategoryList">
          <CategoryItem
            v-for="item in mutatedCategories[area.id]"
            :key="item.id"
            :item="item" />
        </div>
      </div>

      <!-- Project options -->
      <div class="ProjectOptions">
        <template
          v-for="item in allProjectOptions">

          <router-link
            v-if="item.to"
            :key="item.id"
            :to="item.to"
            :class="{ DisabledLink: item.isDisabled }">
            <ProjectOptionListItem :item="item" />
          </router-link>

          <ProjectOptionListItem
            v-else
            :key="item.id"
            :item="item"
            @item-click="onProjectOptionListItemClick" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoryItem from '@/components/ScreeningItems/CategoryItem.vue'
import UnitSelection from '@/components/UpdateFlow/UnitSelection.vue'
import ProjectOptionListItem from '@/components/UpdateFlow/ProjectOptionListItem.vue'
import NextStepLine from '@/components/ScreeningItems/NextStepLine.vue'

import SamplingIcon from '@/assets/svg/with-padding/case.svg?inline'
import FloorplanIcon from '@/assets/svg/categories/plinth.svg?inline'
import UnitsIcon from '@/assets/svg/with-padding/buildings.svg?inline'
import AmountsIcon from '@/assets/svg/with-padding/amount.svg?inline'
import CameraIcon from '@/assets/svg/with-padding/camera.svg?inline'
import InfoIcon from '@/assets/svg/with-padding/information.svg?inline'
import HUBIcon from '@/assets/svg/with-padding/hub-account.svg?inline'
import PCBIcon from '@/assets/svg/with-padding/pcb.svg?inline'
import TextIcon from '@/assets/svg/with-padding/text.svg?inline'

export default {
  name: 'ScreeningsUpdate',
  data() {
    return {
      SamplingIcon,
      FloorplanIcon,
      UnitsIcon,
      AmountsIcon,
      CameraIcon,
      InfoIcon,
      HUBIcon,
      PCBIcon,
      TextIcon,
    }
  },
  computed: {
    ...mapGetters([
      'nextStep',
      'areasAsArray',
      'categoriesAsArray',
      'currentScreening',
      'currentScreeningSelectedFilterUnitID',
      'floorPlanStatus',
      'amountStatus',
      'pcbScreeningStatus',
      'profileImageStatus',
      'samplesStatus',
      'screeningTypes',
      'skippedCategories',
      'categoriesAsObject',
      'materialsAsObject',
      'hubAccountsAsObject',
    ]),
    mutatedCategories() {
      const mutatedCategories = this.areasAsArray.reduce((prev, area) => {
        prev[area.id] = []
        return prev
      }, {})

      this.categoriesAsArray.forEach((category) => {
        if (!category.options.isActive) {
          return
        }

        const mutatedCategory = {
          ...category,
          status: {
            text: '',
            isApproved: false,
          },
          sampleCount: 0,
          assessmentCount: 0,
        }

        // Find types for this category
        let types = []
        if (this.currentScreeningSelectedFilterUnitID) {
          types = this.screeningTypes.filter(
            (x) => x.categoryID === category.id
            && x.unitIDs.includes(this.currentScreeningSelectedFilterUnitID),
          )
        }
        else {
          types = this.screeningTypes.filter(
            (x) => x.categoryID === category.id,
          )
        }

        // Find types for connected categories
        const connectedMaterialIDs = category.connectedMaterialIDs || []
        const connectedTypeIDs = category.connectedTypeIDs || []
        if (connectedMaterialIDs.length || connectedTypeIDs.length) {
          const connectedTypes = []

          this.screeningTypes.forEach((type) => {
            // Check for filtered unit
            if (
              this.currentScreeningSelectedFilterUnitID
              && !type.unitIDs.includes(this.currentScreeningSelectedFilterUnitID)
            ) {
              return
            }

            // Check that type is from same category
            if (type.categoryID === category.id) {
              types.push(type)
              return
            }

            // Check if type is connected to category
            const connectedMaterialIDs = category.connectedMaterialIDs || []
            const connectedTypeIDs = category.connectedTypeIDs || []
            const typeCategory = this.categoriesAsObject[type.categoryID]
            const resourceType = type.getResourceType()
            const resourceTypeMaterialID = resourceType?.materialIDs?.[0] || ''

            let isConnected = false
            // - Check if material is connected to category
            if (connectedMaterialIDs.includes(resourceTypeMaterialID)) {
              isConnected = true
            }

            // Check if type is connected to category
            if (connectedTypeIDs.includes(resourceType.id)) {
              isConnected = true
            }

            // Check if from same area. Allow insulation to be gathered from all areas
            if (
              category.areaID !== 'AREA-3'
              && category.areaID !== typeCategory.areaID
              && category.id !== 'C-13' // Insulation
            ) {
              isConnected = false
            }

            if (isConnected) {
              connectedTypes.push(type)
            }
          })

          types = types.concat(connectedTypes)
        }

        // Find samples and assessments
        mutatedCategory.sampleCount = types.reduce((prev, type) => {
          // Deprecated: Resale logic
          if (type.resale === 'yes') {
            return prev
          }
          if (type.samples.items.length) {
            prev += 1
          }
          if (type.coating.samples.items.length) {
            prev += 1
          }
          return prev
        }, 0)
        // Deprecated: Resale logic
        mutatedCategory.assessmentCount = types.filter(
          (x) => x.assessments.items.length && x.resale !== 'yes',
        ).length

        // Set skipped status
        let isSkipped = !!this.skippedCategories?.general?.includes(category.id)
        if (!isSkipped && this.currentScreeningSelectedFilterUnitID) {
          isSkipped = !!this.skippedCategories?.[
            this.currentScreeningSelectedFilterUnitID
          ]?.includes(category.id)
        }
        if (!isSkipped) {
          isSkipped = !Object.keys(this.skippedCategories).find(
            (key) => key !== 'general' && !this.skippedCategories[key].includes(category.id),
          )
        }

        // Set approved status
        if (types.length) {
          mutatedCategory.status.text = `${ types.length } ${ types.length === 1 ? this.mixWB('TYPE').toLowerCase() : this.mixWB('TYPES').toLowerCase() }`
          mutatedCategory.status.isApproved = true
        }
        else if (isSkipped) {
          mutatedCategory.status.text = this.mixWB('SKIPPED')
          mutatedCategory.status.isApproved = true
        }
        else {
          mutatedCategory.status.text = this.mixWB('NOT_FILLED')
          mutatedCategory.status.isApproved = false
        }

        // Add to correct place
        mutatedCategories[category.areaID].push(mutatedCategory)
      })

      return mutatedCategories
    },
    samplingOption() {
      const data = {
        id: 'sampling',
        icon: this.SamplingIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('SAMPLING'),
        subText: '',
        to: {
          name: 'ScreeningsUpdateSamples',
          params: { screeningID: this.currentScreening.id },
        },
      }

      if (!this.samplesStatus.total) {
        data.isDisabled = true
        data.subText = this.mixWB('NO_SAMPLES_REQUIRED')
        return data
      }

      data.mainText = `${ this.mixWB('SAMPLING') } (${ this.samplesStatus.done }/${ this.samplesStatus.total })`

      if (this.samplesStatus.done === this.samplesStatus.total) {
        data.isComplete = true
        data.subText = this.mixWB('ALL_SAMPLING_IS_DONE')
      }
      else {
        data.subText = this.mixWB('TAKE_SAMPLES')
      }

      return data
    },
    amountsOption() {
      const data = {
        id: 'amounts',
        icon: this.AmountsIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('AMOUNTS'),
        subText: '',
        to: {
          name: 'ScreeningsUpdateAmounts',
          params: { screeningID: this.$route.params.screeningID },
        },
      }

      if (!this.amountStatus.total) {
        data.isDisabled = true
        data.subText = this.mixWB('NO_TYPES_ADDED')
        return data
      }

      data.mainText = `${ this.mixWB('AMOUNTS') } (${ this.amountStatus.done }/${ this.amountStatus.total })`

      if (this.amountStatus.done === this.amountStatus.total) {
        data.isComplete = true
        data.subText = this.mixWB('ALL_TYPES_HAS_AMOUNTS')
      }
      else {
        data.subText = this.mixWB('SPECIFY_QUANTITIES')
      }

      return data
    },
    pcbOption() {
      const data = {
        id: 'pcb-screenings',
        icon: this.PCBIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('PCB_SCREENINGS'),
        subText: '',
        to: {
          name: 'ScreeningsUpdatePSCScreeningSchema',
          params: { screeningID: this.currentScreening.id },
        },
      }

      if (!this.pcbScreeningStatus.total) {
        data.isDisabled = true
        data.subText = this.mixWB('NO_PCBSCREENINGS_ARE_REQUIRED')
        return data
      }

      data.mainText = `${ this.mixWB('PCB_SCREENINGS') } (${ this.pcbScreeningStatus.done }/${ this.pcbScreeningStatus.total })`

      if (this.pcbScreeningStatus.done === this.pcbScreeningStatus.total) {
        data.isComplete = true
        data.subText = this.mixWB('ALL_PCB_DONE')
      }
      else {
        data.subText = this.mixWB('PERFORM_PCB_SCREENINGS')
      }

      return data
    },
    profileImagesOption() {
      const data = {
        id: 'profile-images',
        icon: this.CameraIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('PROFILE_IMAGES'),
        subText: '',
        to: {
          name: 'ScreeningsUpdateProfileImages',
          params: { screeningID: this.$route.params.screeningID },
        },
      }

      data.mainText = `${ this.mixWB('PROFILE_IMAGES') } (${ this.profileImageStatus.done }/${ this.profileImageStatus.total })`

      if (this.profileImageStatus.done === this.profileImageStatus.total) {
        data.isComplete = true
        data.subText = this.mixWB('ALL_IMAGES_ARE_DONE')
      }
      else {
        data.subText = this.mixWB('TAKE_IMAGES')
      }

      return data
    },
    floorplansOption() {
      const data = {
        id: 'floorplans',
        icon: this.FloorplanIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('FLOOR_PLANS'),
        subText: '',
        to: {
          name: 'ScreeningsUpdateFloorPlans',
          params: { screeningID: this.$route.params.screeningID },
        },
      }

      data.mainText = `${ this.mixWB('FLOOR_PLANS') } (${ this.floorPlanStatus.done }/${ this.floorPlanStatus.total })`

      if (this.floorPlanStatus.done === this.floorPlanStatus.total) {
        data.isComplete = true
        data.subText = this.mixWB('ALL_FLOOR_PLANS_ARE_DONE')
      }
      else {
        data.subText = this.mixWB('UPLOAD_FLOOR_PLANS')
      }

      return data
    },
    unitsOption() {
      const data = {
        id: 'units',
        icon: this.UnitsIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('UNITS'),
        subText: this.mixWB('UNITS_INCLUDED_IN_THE_PROJECT'),
        to: {
          name: 'ScreeningsUpdateBuildings',
          params: { screeningID: this.$route.params.screeningID },
        },
      }

      return data
    },
    detailsOption() {
      const data = {
        id: 'details',
        icon: this.InfoIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('PROJECT_DETAILS'),
        subText: this.mixWB('INFORMATION_ABOUT_THE_PROJECT'),
        to: {
          name: 'ScreeningsUpdateProjectDetails',
          params: { screeningID: this.$route.params.screeningID },
        },
      }

      return data
    },
    hubOption() {
      const { hubAccountItems } = this.currentScreening.data

      let subText = this.mixWB('W: Ingen valgt')

      if (hubAccountItems.length) {
        const projectOwnerHUBAccount = this.currentScreening.data.screeningOwnerHUBAccount

        if (projectOwnerHUBAccount) {
          subText = projectOwnerHUBAccount.name

          if (hubAccountItems.length > 1) {
            subText += ` (+ ${ hubAccountItems.length - 1 } mere)`
          }
        }
        else if (hubAccountItems.length === 1) {
          subText = this.mixWB('W: 1 valgt')
        }
        else {
          subText = this.mixWB('W: %s valgt', [hubAccountItems.length])
        }
      }

      const data = {
        id: 'hub',
        icon: this.HUBIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('W: HUB kunder'),
        subText,
      }

      return data
    },
    commentsOption() {
      const data = {
        id: 'comments',
        icon: this.TextIcon,
        isDisabled: false,
        isComplete: false,
        mainText: this.mixWB('COMMENTS_2'),
        subText: this.mixWB(''),
      }

      return data
    },
    allProjectOptions() {
      const options = [
        this.samplingOption,
        this.amountsOption,
        this.pcbOption,
        this.profileImagesOption,
        this.floorplansOption,
        this.unitsOption,
        this.detailsOption,
      ]

      if (this.currentScreening.data.accountID === 'bpZulvjI5VnFL9zVO0zb') {
        options.push(this.hubOption)
      }

      options.push(this.commentsOption)

      return options
    },
  },
  methods: {
    onProjectOptionListItemClick(itemID) {
      if (itemID === 'comments') {
        this.$emit('project-comments')
      }
      if (itemID === 'hub') {
        this.$emit('hub-account-select')
      }
    },
  },
  components: {
    CategoryItem,
    UnitSelection,
    ProjectOptionListItem,
    NextStepLine,
  },
}
</script>

<style lang="stylus" scoped>
  .ContinueButtonWrap
    padding-top 10px
    margin-bottom 20px

  .PageSection
    margin-bottom 20px

  .SectionTitle
    display block
    margin-bottom 7.5px
    font-weight bold
    text-align center
    text-transform uppercase

  .CategoryList
    display flex
    flex-wrap wrap
    margin 0 -5px 20px

  .ProjectOptions
    margin-top 30px
    border-top 1px solid $color_grey_lighter
    .DisabledLink
      pointer-events none

  .EmptyWrap
    padding 10px 15px
    background-color $color_grey_lightest
    text-align center
    span
      color $color_grey

</style>
