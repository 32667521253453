<template>
  <div class="ScreeningsUpdate">
    <div class="InnerPage">
      <DotMenu
        :use-component="ScreeningMenu"
        :dataToComponent="{
          isScreeningComplete:
            currentScreening
            && currentScreening.data
            && currentScreening.data.isCompleted
        }"
        @project-comments="onOpenProjectComments" />

      <ScreeningHeader />

      <!-- First steps -->
      <ScreeningsUpdateFirstSteps v-if="!isFirstStepDone" />
      <ScreeningsUpdateFlow
        v-else
        @project-comments="onOpenProjectComments"
        @hub-account-select="onOpenHUBAccountModal" />
    </div>

    <Dialog
      :isShowing="showDialog"
      :useComponent="ProjectNotesModal"
      :componentProps="{}"
      size="medium"
      @close="showDialog = false" />

    <Dialog
      :isShowing="showHUBDialog"
      :useComponent="HUBAccountsSelectModal"
      :componentProps="{}"
      size="small"
      @close="showHUBDialog = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import ScreeningHeader from '@/components/ScreeningItems/ScreeningHeader.vue'
import ScreeningMenu from '@/components/ScreeningItems/Menus/ScreeningMenu.vue'
import ScreeningsUpdateFlow from '@/views/ScreeningsUpdateFlow.vue'
import ProjectNotesModal from '@/components/Modals/ProjectNotesModal.vue'
import HUBAccountsSelectModal from '@/components/Modals/HUBAccountsSelectModal/HUBAccountsSelectModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import ScreeningsUpdateFirstSteps from '@/views/ScreeningsUpdateFirstSteps.vue'

export default {
  name: 'ScreeningsUpdate',
  data() {
    return {
      ScreeningMenu,
      isStarting: false,
      // Dialog
      showDialog: false,
      ProjectNotesModal,

      showHUBDialog: false,
      HUBAccountsSelectModal,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'isFirstStepDone',
    ]),
  },
  methods: {
    onOpenProjectComments() {
      this.showDialog = true
    },
    onOpenHUBAccountModal() {
      this.showHUBDialog = true
    },
  },
  components: {
    DotMenu,
    ScreeningHeader,
    ScreeningsUpdateFlow,
    Dialog,
    ScreeningsUpdateFirstSteps,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdate
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()
    >>> .DotMenu
      top -20px
      right -10px

  .FirstStepLink
    display block
    margin-bottom 10px

</style>
