<template>
  <div class="SearchSelect">
    <div
      class="MainContentWrap"
      @click="$refs.SearchField.focus()">
      <!-- Selected items -->
      <div
        class="SelectedItem"
        v-for="item in selectedItems"
        :key="item.id"
        @click.stop="">
        <span>{{ mixWB(item.title) }}</span>
        <div
          v-if="!disabled"
          class="CloseIcon"
          @click="onRemoveItem(item)">
          <CloseIcon />
        </div>
      </div>

      <!-- Next item -->
      <input
        v-show="maxResults > selectedItems.length && !disabled"
        :id="name"
        :name="name"
        autocomplete="off"
        ref="SearchField"
        :placeholder="mixWB('SELECT_FROM_LIST_PLACEHOLDER')"
        v-model="searchTerm"
        @input="onSearchTermInput"
        @focus="onSearchTermInput" />

      <!-- Suggestions -->
      <div
        v-if="showSuggestions"
        class="Backdrop"
        @click.stop="onBackdropClick" />
      <div
        v-if="showSuggestions"
        class="Suggestions">
        <span
          v-if="!suggestions.length && !allowCustomText"
          class="NoMatch">{{ mixWB('NO_MATCH_FOUND') }}</span>
        <span
          v-if="searchTerm.length && allowCustomText"
          class="CustomText"
          @click="onCustomTextClick">{{ mixWB('CREATE_NEW') }}: {{ searchTerm }}</span>
        <span
          class="Suggestion"
          v-for="suggestion in suggestions"
          :key="suggestion.id"
          @click.stop="onSuggestionClick(suggestion)">{{ suggestion.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/svg/close.svg?inline'
import { orderBy } from 'lodash-es'

export default {
  name: 'SearchSelect',
  props: {
    name: {
      type: String,
      required: false,
    },
    data: {
      type: [String, Array],
      required: true,
    },
    itemList: {
      type: Array,
      required: true,
    },
    maxResults: {
      type: Number,
      required: false,
      default: 1,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    allowCustomText: {
      type: Boolean,
      required: false,
      default: false,
    },
    customText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: '',
      showSuggestions: false,
    }
  },
  computed: {
    selectedItems() {
      // Data as string
      if (typeof this.data === 'string') {
        if (!this.data && !this.customText) {
          return []
        }

        if (this.customText) {
          return [
            {
              id: 'custom-text',
              title: this.customText,
              isCustomText: true,
            },
          ]
        }

        const item = this.itemList.find((x) => x.id === this.data)

        if (!item) {
          return []
        }

        return [
          {
            id: item.id,
            title: this.mixWB(item.translation),
          },
        ]
      }

      // Data as array
      if (!this.data.length) {
        return []
      }

      return this.itemList.reduce((prev, item) => {
        if (this.data.includes(item.id)) {
          prev.push(
            {
              id: item.id,
              title: this.mixWB(item.translation),
            },
          )
        }
        return prev
      }, [])
    },
    suggestions() {
      // No search term
      if (!this.searchTerm) {
        const suggestions = []
        this.itemList.forEach((item) => {
          // Don't show already selected
          if (typeof this.data === 'object' && this.data.length) {
            if (this.data.includes(item.id)) {
              return
            }
          }
          suggestions.push({
            id: item.id,
            title: this.mixWB(item.translation),
          })
        })

        return orderBy(suggestions, ['title'], ['asc'])
      }

      // With search term
      const suggestions = this.itemList.reduce((prev, item) => {
        const searchTerm = this.searchTerm.toLowerCase()
        const text = this.mixWB(item.translation).toLowerCase()

        // Don't show already selected
        if (typeof this.data === 'object') {
          if (this.data.includes(item.id)) {
            return prev
          }
        }

        // Exact match
        if (text === searchTerm) {
          prev.push({
            id: item.id,
            title: this.mixWB(item.translation),
            score: 100,
          })
        }

        // Partial match
        else if (text.includes(searchTerm)) {
          prev.push({
            id: item.id,
            title: this.mixWB(item.translation),
            score: Math.ceil((searchTerm.length / text.length) * 100),
          })
        }
        return prev
      }, [])

      return orderBy(suggestions, ['score'], ['desc'])
    },
  },
  methods: {
    onSearchTermInput() {
      this.showSuggestions = true
    },
    onRemoveItem(item) {
      // Data as string
      if (typeof this.data === 'string') {
        if (item.isCustomText) {
          this.$emit('custom-text-update', { name: this.name, value: '' })
        }
        else {
          this.$emit('update', { name: this.name, value: '' })
        }
        return
      }

      // Data as array
      const index = this.data.findIndex((x) => x === item.id)
      const dataCopy = [...this.data]
      dataCopy.splice(index, 1)
      this.$emit('update', { name: this.name, value: dataCopy })
    },
    onCustomTextClick() {
      this.$emit('custom-text-update', { name: this.name, value: this.searchTerm })

      this.searchTerm = ''
      this.showSuggestions = false
    },
    onBackdropClick() {
      this.showSuggestions = false
    },
    onSuggestionClick(suggestion) {
      // Data as string
      if (typeof this.data === 'string') {
        this.$emit('update', { name: this.name, value: suggestion.id })
      }
      // Data as array
      else {
        this.$emit('update', { name: this.name, value: [...this.data].concat(suggestion.id) })
      }

      this.searchTerm = ''
      this.showSuggestions = false
    },
  },
  components: {
    CloseIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .SearchSelect
    display block
    margin-bottom 10px

  .MainContentWrap
    position relative
    background-color $color_grey_lightest
    border 1px solid $color_grey_lighter
    min-height 40px
    display flex
    flex-wrap wrap
    align-items flex-start
    .SelectedItem
      height 30px
      display flex
      align-items center
      border 1px solid #000
      padding 5px
      margin 4px 5px
      min-width 0
      span
        truncated()
      .CloseIcon
        box(30px)
        flex-center-children()
        padding 10px
        margin-right -5px
        cursor pointer
        &:hover
          svg
            fill $color_grey_dark
    input
      border none
      background-color transparent
      height 30px
      margin 4px 5px
      &::placeholder
        font-style italic
    .Backdrop
      position fixed
      top 0
      left 0
      box(100vw, 100vh)
    .Suggestions
      position absolute
      left -1px
      top 100%
      z-index 2
      width calc(100% + 2px)
      max-height 300px
      overflow-y scroll
      background-color #fff
      border 1px solid $color_grey
      box-shadow $box_shadow_1
      .NoMatch
        padding 5px 10px
        font-style italic
      .CustomText,
      .Suggestion
        padding 6px 10px
        cursor pointer
        &:hover
          background-color $color_tinted_blue
</style>
